import React from 'react';
import { createBrowserRouter, Link } from 'react-router-dom';
import App from '../../App';
import CardView from '../../components/Card/components/CardView';
import Profile from '../../components/Workspace/Profile/Profile';
import Home from '../../components/Workspace/Home/Home';
import Board from '../../components/Workspace/Board/Board';
import EventView from '../../components/Calendar/components/EventView';
import Redirect from '../../components/Workspace/Redirect/Redirect';
import Registry from '../../components/Authorization/Registry';
import Calendar from '../../components/Calendar/Calendar';
import Explorer from '../../components/Explorer/Explorer';
import { Result } from 'antd';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'board/:boardId',
        element: <Board />,
        children: [
          {
            path: 'card/:id',
            element: <CardView />,
          },
        ],
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'files/:boardId',
        element: <Explorer />,
      },
      {
        path: 'calendar/:boardId',
        element: <Calendar />,
        children: [
          {
            path: ':eventId',
            element: <EventView />,
          },
        ],
      },
      {
        path: 'redirect',
        element: <Redirect />,
      },
    ],
  },
  { path: '/registry', element: <Registry /> },
  {
    path: '*',
    element: (
      <Result
        status={404}
        title={'404'}
        subTitle={'По данному адресу ничего не найдено'}
        extra={<Link to={'/'}>Вернуться на главную страницу</Link>}
      />
    ),
  },
]);
